import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import Img from "gatsby-image";

import MoreLink from "./more-link";
import PageTitle from "./page-title";

import offerLayoutCss from "./offer-layout.module.css";

// styles
const pStyles = {
  fontWeight: 200,
  lineHeight: 1.65,
  fontSize: "13pt",
  paddingTop: "1rem",
};

// markup
const OfferLayout = ({ config }) => {
  const { index, path, slug } = config;

  const { images } = useStaticQuery(graphql`
    query LandingImages {
      images: allFile(filter: {relativeDirectory: {eq: "offers"}}) {
        edges {
          node {
            id
            name
            childImageSharp {
              fixed(width: 360) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `);

  const image = images.edges.find(({node}) => node.name === slug);

  const img = <Link to={path}><Img fixed={image.node.childImageSharp.fixed} alt={config.alt} /></Link>;

  const text = (
    <div
      className={`${offerLayoutCss.textWrapper} ${
        index % 2 === 0 ? "" : offerLayoutCss.reverse
      }`}
      style={{
        textAlign: index % 2 === 0 ? "end" : "start",
        paddingLeft: index % 2 === 0 ? "0" : "2rem",
        paddingRight: index % 2 === 0 ? "2rem" : "0",
      }}
    >
      <PageTitle title={config.title} />
      <p style={pStyles}>{config.description}</p>
      <MoreLink path={path} />
    </div>
  );

  return (
    <>
      {index % 2 === 0 ? text : img}
      {index % 2 === 0 ? img : text}
    </>
  );
};

export default OfferLayout;
