import React, { Component } from "react";

import offerCss from "./offer-section.module.css";
import { prefix, suffix } from "./offer-section.utils";

// markup
class OfferSection extends Component {
  render() {
    const {
      config: { index, color },
      children,
    } = this.props;

    const prefixEl = prefix(index % 2 === 0, color);
    const suffixEl = suffix(index % 2 === 0, color);
    return (
      <section style={{ marginTop: "-6.1rem" }}>
        {prefixEl}
        <article
          className={`${offerCss.article} ${index % 2 === 0 ? "" : offerCss.reverse}`}
          style={{
            background: color,
          }}
        >
          {children}
        </article>
        {suffixEl}
      </section>
    );
  }
}

export default OfferSection;
