import React from "react";

import BackLink from './back-link';

const wrapperStyles = {
  display: "flex",
  borderBottom: "2px solid rgba(0,0,0,.125)",
  justifyContent: "space-between",
  paddingBottom: "1rem",
};

const titleStyles = {
  fontWeight: 700,
  fontSize: "1.2rem",
  letterSpacing: ".1em",
  textTransform: "uppercase",
};

const PageTitle = ({ title, showBack = false }) => (
  <div style={wrapperStyles}>
    <h1 style={titleStyles}>{title}</h1>
    {showBack ? <BackLink /> : null}
  </div>
);

export default PageTitle;
