import React from "react";
import { Link } from "gatsby";

import ChevronBackSvg from "../images/icons/chevron-back.svg";

const iconStyles = {
  fill: "whitesmoke",
  height: "1rem",
  width: "1rem",
};

const wrapperStyle = {
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
};

const iconWrapperStyles = {
  marginRight: ".5rem",
  border: "solid 2px rgba(255, 255, 255, .5)",
  borderRadius: "50%",
  width: "1.5rem",
  height: "1.5rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const pStyles = {
  textTransform: "uppercase",
  marginLeft: "1rem",
  fontSize: ".8rem",
};

const BackLink = () => (
  <Link to="/" style={wrapperStyle}>
    <div style={iconWrapperStyles}>
      <img style={iconStyles} src={ChevronBackSvg} alt="back icon" />
    </div>
    <p style={pStyles}>wróć</p>
  </Link>
);

export default BackLink;
