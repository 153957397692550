import React from "react";
import { Link } from "gatsby";

import ChevronForwardSvg from "../images/icons/chevron-forward.svg";

const iconStyles = {
  fill: "whitesmoke",
  height: "1.2rem",
  width: "1.2rem",
};

const wrapperStyle = {
  marginTop: "1rem",
  display: "flex",
  alignItems: "center",
  textDecoration: "none"
};

const iconWrapperStyles = {
  marginRight: ".5rem",
  border: "solid 2px rgba(255, 255, 255, .5)",
  borderRadius: "50%",
  width: "1.8rem",
  height: "1.8rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const pStyles = {
  textTransform: "uppercase",
  marginLeft: "1rem",
  fontSize: ".8rem",
};

const MoreLink = ({ path = "#" }) => (
  <Link to={path} style={wrapperStyle}>
    <div style={iconWrapperStyles}>
      <img style={iconStyles} src={ChevronForwardSvg} alt="more icon" />
    </div>
    <p style={pStyles}>więcej</p>
  </Link>
);

export default MoreLink;
