import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import OfferSection from "../components/offer-section";
import OfferLayout from "../components/offer-layout";

// data
const offers = [
  {
    title: "Rolety",
    color: "rgb(92, 107, 192)",
    description:
      "Rolety stworzone z myślą o Twoim wnętrzu. Niezawodne mechanizmy, szeroka paleta materiałów i kolorów.",
    slug: "rolety",
    alt: "Spacius room with wooden blinds, sunrays coming through",
    path: "/rolety",
  },
  {
    title: "Plisy",
    color: "rgb(63, 81, 181)",
    description:
      "Nowoczesna plisa skutecznie zastąpi tradycyjną żaluzję dodając przy okazji praktyczności.",
    slug: "plisy",
    alt: "Spacius room with wooden blinds, sunrays coming through",
    path: "/plisy",
  },
  {
    title: "Moskitiery",
    color: "rgb(57, 73, 171)",
    description:
      "Moskitiera jest idealną metodą na powstrzymanie owadów przed wejściem do wnętrza naszego domostwa. Zatrzymaj komary na zewnątrz!",
    slug: "moskitiery",
    alt: "Spacius room with wooden blinds, sunrays coming through",
    path: "/moskitiery",
  },
  {
    title: "Żaluzje",
    color: "rgb(48, 63, 159)",
    description:
      "Klasyczny i doceniony system osłon okiennych. Sprawdzi się w każdym domu i doda niepowtarzalnego klimatu wnętrzu.",
    slug: "zaluzje",
    alt: "Spacius room with wooden blinds, sunrays coming through",
    path: "/zaluzje",
  },
];

// markup
const IndexPage = () => (
  <Layout>
    <SEO
      title="Rolety Lider | Kraków | Rolety dzień noc | Plisy | Moskitiery"
      description="Profesjonalny montaz rolet na terenie Krakow i okolic. 25 lat doświadczenia - materiały od sprawdzonych producentów."
    />
    {offers.map((item, index) => (
      <OfferSection key={index} config={{ ...item, index }}>
        <OfferLayout config={{ ...item, index }} />
      </OfferSection>
    ))}
  </Layout>
);

export default IndexPage;
